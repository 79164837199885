import ForwardIcon from '@mui/icons-material/Forward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import React from 'react';
import { ReactComponent as CalSurance } from '../../assets/calsurance.svg';
import { DisplayValue } from '../../components/display-value';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { CTR_GROUP, EOPolicyData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { isAgt } from '../../utils/is-agent';
import { PolicyAdd } from './policy-add';
import { PolicyDelete } from './policy-delete';
import { PolicyEdit } from './policy-edit';
import { PolicyUpload } from './policy-upload';
import { PolicyView } from './policy-view';

export function ErrorsOmissions() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [status, setStatus] = React.useState<'updating' | 'deleting'>();
  // Hooks
  const { hasGroup } = usePermissions();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Errors & Omissions
  const path = '/agent/eo';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: EOPolicyData | null;
        } = await API.post('ContractingAPI', path, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Read/Write for current user and Admins
  // Read only for upline impersonating downline
  const canEdit =
    hasGroup(CTR_GROUP.CTR_GlobalAdmin) || impersonatedAgent === null;

  const { data } = query;
  const hasPolicy =
    data !== null && data !== undefined && data.EOCarrier !== null;

  return (
    <RouteContainer
      routeTitle="Errors & Omissions"
      withContainer
      maxWidth="md"
      loading={query.isLoading}
      hasError={query.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          {hasPolicy ? (
            <Paper elevation={0} sx={{ border: '1px solid #e1e1e1' }}>
              <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" noWrap component="div">
                  Your E&O Policy
                </Typography>

                <Box sx={{ flex: 1 }} />

                <PolicyEdit
                  AgtNo={AgtNo}
                  data={data}
                  open={status === 'updating'}
                  onClose={() => setStatus(undefined)}
                />

                <PolicyDelete
                  AgtNo={AgtNo}
                  open={status === 'deleting'}
                  onClose={() => setStatus(undefined)}
                />

                {canEdit ? (
                  <Tooltip title="Manage E&O policy" placement="left" arrow>
                    <IconButton
                      size="small"
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                  <MenuItem
                    onClick={() => {
                      setStatus('updating');
                      handleClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatus('deleting');
                      handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>

              <Box sx={{ px: 1, pb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DisplayValue label="Carrier" value={data.EOCarrier} />
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayValue
                      label="Coverage Amount"
                      value={
                        data.EOCovAmt
                          ? toCurrency({ value: Number(data.EOCovAmt) })
                          : null
                      }
                      monospace
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayValue
                      label="Policy"
                      value={data.EOPolNo}
                      monospace
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayValue
                      label="Certificate"
                      value={data.EOCertNo}
                      monospace
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ p: 1 }}>
                      <Box
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: '#9b9b9b',
                        }}
                      >
                        Effective
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontFamily: 'Roboto Mono', fontSize: 18 }}>
                          {data.EOEffDate || '---'}
                        </Box>

                        {data.EOEffDate ? (
                          <Box
                            sx={{
                              paddingLeft: 1,
                              fontSize: 14,
                              color: '#7a7a7a',
                            }}
                          >
                            {formatDistanceToNowStrict(
                              new Date(data.EOEffDate),
                              {
                                addSuffix: true,
                              }
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ p: 1 }}>
                      <Box
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: '#9b9b9b',
                        }}
                      >
                        Expiring
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontFamily: 'Roboto Mono', fontSize: 18 }}>
                          {data.EOExpDate || '---'}
                        </Box>

                        {data.EOExpDate ? (
                          <Box
                            sx={{
                              paddingLeft: 1,
                              fontSize: 14,
                              color: '#7a7a7a',
                            }}
                          >
                            {formatDistanceToNowStrict(
                              new Date(data.EOExpDate),
                              {
                                addSuffix: true,
                              }
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ p: 2 }}>
                {canEdit && data.Filename ? (
                  <PolicyView AgtNo={AgtNo} policy={data} />
                ) : canEdit ? (
                  <PolicyUpload AgtNo={AgtNo} />
                ) : null}
              </Box>
            </Paper>
          ) : (
            <Paper elevation={0} sx={{ p: 8, border: '1px solid #eee' }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Roboto Mono',
                  color: '#9b9b9b',
                }}
              >
                No errors & omissions policy
              </Typography>
            </Paper>
          )}

          <Divider />

          {canEdit ? <PolicyAdd AgtNo={AgtNo} /> : null}

          <Paper
            elevation={0}
            component="a"
            href="https://www.calsurance.com/NAA"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecorationLine: 'none',
              minHeight: 64,
              display: 'flex',
              alignItems: 'center',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#03182E22',
              cursor: 'pointer',
              transition: 'all 0.2s',
              ':hover': {
                transform: 'scale(1.01)',
                borderColor: '#03182E',
              },
              ':hover #forward-icon': {
                color: '#03182E',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                alignItems: {
                  xs: 'flex-start',
                  md: 'center',
                },
              }}
            >
              <Box sx={{ padding: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 88,
                    minHeight: 52,
                  }}
                >
                  <CalSurance style={{ maxWidth: 88, maxHeight: 52 }} />
                </Box>
              </Box>
              <Box sx={{ padding: 1, fontSize: 22 }}>
                Purchase new E&O Policy
              </Box>
            </Box>

            <Box sx={{ flex: 1 }} />

            <Box
              sx={{
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ForwardIcon
                id="forward-icon"
                sx={{ transition: 'all 0.2s', color: '#03182E22' }}
              />
            </Box>
          </Paper>
        </Stack>
      </Box>
    </RouteContainer>
  );
}
