import ForwardIcon from '@mui/icons-material/Forward';
import LogoutIcon from '@mui/icons-material/Logout';
import PinIcon from '@mui/icons-material/Pin';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API, Auth } from 'aws-amplify';
import React from 'react';
import { materialTheme } from '../theme';
import { AuthorizeData } from '../types';
import { captureError } from '../utils/capture-error';

export function AuthorizeVerify(props: { data: AuthorizeData }) {
  // State
  const [PIN, setPIN] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  const queryClient = useQueryClient();

  // Query - Generate PIN for User on page load
  const pathPIN = '/agent/pin/request';
  const queryPIN = useQuery({
    retry: false,
    queryKey: [pathPIN],
    queryFn: async () => {
      await API.post('ContractingAPI', pathPIN, {});
      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Verify PIN for account
  const mutationVerify = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/pin/confirm', {
        body: { PIN, PINType: 'Registration' },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['/authorize'] });
    },
    onError: (error: any) => {
      if (error?.response?.data?.error) {
        setErrMsg(error.response.data.error);
      }
      captureError({ data: { error } });
    },
  });

  // Mutation - Generate a new PIN
  const mutationGenerate = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/pin/request', {});
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const submitDisabled = !PIN.match(/^[0-9]{6}$/);

  if (queryPIN.isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  } else {
    let pleaseWait: JSX.Element | null = null;
    if (mutationGenerate.error instanceof Error) {
      if (
        // @ts-ignore
        mutationGenerate.error.response?.data?.error.includes('Please wait')
      ) {
        pleaseWait = (
          <Alert severity="error" onClose={() => mutationGenerate.reset()}>
            <strong style={{ display: 'block' }}>Pin already generated!</strong>
            <small>
              Please wait 15 minutes before attempting to generate a new Pin.
            </small>
          </Alert>
        );
      }
    }

    return (
      <Box
        sx={{
          height: '100dvh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 320 }}>
          <Stack spacing={2}>
            <Paper sx={{ overflow: 'hidden' }}>
              <Paper
                elevation={0}
                sx={{
                  p: 1,
                  color: '#fff',
                  backgroundColor: materialTheme.palette.primary.main,
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '4px solid #00C868',
                  borderRadius: 0,
                }}
              >
                <PinIcon fontSize="large" />
                <Box sx={{ pl: 1, fontSize: 24, fontWeight: 'bold' }}>
                  Verify Account
                </Box>
              </Paper>

              <Box sx={{ p: 1, fontSize: 18 }}>
                In the next few minutes you will receive a text message on your
                mobile phone with a <strong>Pin</strong> that can be input below
                to verify your account.
              </Box>

              <Box sx={{ p: 1, fontSize: 14, color: '#4a4a4a' }}>
                This <strong>Pin</strong> is valid for 15 minutes. If you have
                not received the Pin within 15 minutes please click the button
                below to generate a new Pin.
              </Box>

              <Box sx={{ p: 1 }}>
                <TextField
                  id="verify-account-pin"
                  disabled={mutationVerify.isLoading}
                  autoFocus
                  fullWidth
                  label="Pin"
                  placeholder="000000"
                  type="number"
                  value={PIN}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value.trim().length <= 6) {
                      setPIN(value);
                    }
                  }}
                  inputProps={{
                    style: { fontFamily: 'Roboto Mono', fontSize: 22 },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              {mutationVerify.isError ? (
                <Box sx={{ p: 1 }}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      mutationVerify.reset();
                      setErrMsg('');
                    }}
                  >
                    {errMsg ? (
                      <Box>{errMsg}</Box>
                    ) : (
                      <Box>
                        <strong style={{ display: 'block' }}>
                          Error submitting pin!
                        </strong>
                        <small>Please refresh the page and try again.</small>
                      </Box>
                    )}
                  </Alert>
                </Box>
              ) : null}

              <Box sx={{ p: 1 }}>
                <Button
                  disabled={
                    submitDisabled ||
                    mutationVerify.isLoading ||
                    mutationVerify.isError
                  }
                  fullWidth
                  variant="contained"
                  disableElevation
                  color="info"
                  endIcon={<ForwardIcon />}
                  onClick={() => mutationVerify.mutate()}
                >
                  {mutationVerify.isLoading ? 'Submitting...' : 'Submit pin'}
                </Button>
              </Box>
            </Paper>

            {pleaseWait !== null ? (
              pleaseWait
            ) : mutationGenerate.isError ? (
              <Alert severity="error" onClose={() => mutationGenerate.reset()}>
                <strong style={{ display: 'block' }}>
                  Error generating new pin!
                </strong>
                <small>Please refresh the page and try again.</small>
              </Alert>
            ) : null}

            <Box>
              {mutationGenerate.isSuccess ? (
                <Alert
                  severity="success"
                  onClose={() => mutationGenerate.reset()}
                >
                  <strong style={{ display: 'block' }}>
                    Generated new pin!
                  </strong>
                  <small>
                    Please wait several minutes for a text message to be sent
                    with your pin.
                  </small>
                </Alert>
              ) : (
                <Button
                  disabled={
                    mutationGenerate.isLoading || mutationGenerate.isError
                  }
                  fullWidth
                  size="small"
                  variant="outlined"
                  onClick={() => mutationGenerate.mutate()}
                >
                  {mutationGenerate.isLoading
                    ? 'Generating...'
                    : 'Generate new pin'}
                </Button>
              )}
            </Box>

            <Box sx={{ fontSize: 15, color: '#4a4a4a' }}>
              If you plan to complete account verification later please{' '}
              <strong>Sign Out</strong>.
            </Box>

            <Button
              fullWidth
              color="info"
              size="small"
              variant="outlined"
              startIcon={<LogoutIcon />}
              onClick={() => Auth.signOut()}
            >
              Sign out
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }
}
