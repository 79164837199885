import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { PaperExpandable } from '../../components/paper-expandable';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { CTR_GROUP, FileUploadData, FileUploadType } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { DocListItem } from './doc-list-item';
import { DocUpload } from './doc-upload';
import { DocView } from './doc-view';

export function Documents() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [doc, setDoc] = React.useState<FileUploadData>();
  // Hooks
  const { hasGroup } = usePermissions();

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Admins can impersonate on the Documents page
  // Managers cannot impersonate downlines
  const enabled: boolean =
    Boolean(
      hasGroup(CTR_GROUP.CTR_GlobalAdmin) && impersonatedAgent !== null
    ) || Boolean(isAgt(AgtNo) && impersonatedAgent === null);

  let impersonateWarning: JSX.Element | null = null;
  if (!hasGroup(CTR_GROUP.CTR_GlobalAdmin) && impersonatedAgent !== null) {
    impersonateWarning = (
      <Alert severity="info">Impersonation does not work on this page.</Alert>
    );
  }

  // Query - Agent Documents and Files
  const path = '/agent/file/list';
  const query = useQuery({
    enabled,
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: FileUploadData[];
        } = await API.post('ContractingAPI', path, {
          body: { AgtNo },
        });

        if (response.data.length) {
          return response.data;
        }
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const CertTypes = [
    FileUploadType['AHIP'],
    FileUploadType['Annuity'],
    FileUploadType['Anti-Money Laundering'],
    FileUploadType['Errors and Omissions'],
  ];
  const DocCerts: FileUploadData[] = [];
  const DocOther: FileUploadData[] = [];
  query.data?.forEach((doc) => {
    if (CertTypes.includes(doc.FileUploadType)) {
      DocCerts.push(doc);
    } else {
      DocOther.push(doc);
    }
  });

  const isLoading = Boolean(query.isLoading && query.fetchStatus !== 'idle');

  return (
    <RouteContainer
      routeTitle="Documents"
      withContainer
      loading={isLoading}
      hasError={query.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          {impersonateWarning}

          <DocUpload AgtNo={AgtNo} />

          <Divider />

          <PaperExpandable title="Certifications" count={DocCerts.length}>
            {DocCerts.length ? (
              <Box component="ul" sx={{ p: 0, m: 0 }}>
                {orderBy(DocCerts, 'CreatedDate', 'desc').map((item) => {
                  return (
                    <DocListItem
                      key={item.FileUploadID}
                      item={item}
                      onClick={() => setDoc(item)}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontFamily: 'Roboto Mono',
                  color: '#9b9b9b',
                }}
              >
                No certifications have been uploaded
              </Box>
            )}
          </PaperExpandable>

          <PaperExpandable title="Additional Documents" count={DocOther.length}>
            {DocOther.length ? (
              <Box component="ul" sx={{ p: 0, m: 0 }}>
                {orderBy(DocOther, 'CreatedDate', 'desc').map((item) => {
                  return (
                    <DocListItem
                      key={item.FileUploadID}
                      item={item}
                      onClick={() => setDoc(item)}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontFamily: 'Roboto Mono',
                  color: '#9b9b9b',
                }}
              >
                No additional documents have been uploaded
              </Box>
            )}
          </PaperExpandable>
        </Stack>

        <DocView AgtNo={AgtNo} doc={doc} onClose={() => setDoc(undefined)} />
      </Box>
    </RouteContainer>
  );
}
