import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { useWindowWidth } from '@react-hook/window-size';
import { API } from 'aws-amplify';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import avatar from '../../assets/naa-logo.jpg';
import { AuthContext } from '../../contexts/auth-context';
import { materialTheme } from '../../theme';
import { AgentProfileData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { CropPhoto } from './crop-photo';

const alertStyle = {
  position: 'fixed',
  opacity: 0.95,
  marginRight: '20px',
};

export function PhotoManager(props: {
  agent?: AgentProfileData['AgtNo'] | null;
  admin?: boolean;
  user?: string | undefined;
  userCanEdit?: boolean;
}) {
  // Context
  const {
    state: { impersonatedAgent },
  } = React.useContext(AuthContext);
  // Props
  const { agent, admin, user } = props;
  // Hooks
  const width = useWindowWidth();
  // State
  const [photo, setPhoto] = React.useState<any>(avatar);
  const [newPhoto, setNewPhoto] = React.useState<any>('');
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  const isMobile = width < 900;
  const responseError = (
    <React.Fragment>
      There was an issue saving your changes. If the problem persists contact{' '}
      <a href={`mailto:help@naaleads.com`}>help@naaleads.com</a>
    </React.Fragment>
  );

  // Load Profile Photo
  React.useEffect(() => {
    let promise: Promise<any> | undefined;
    setLoading(true);
    setPhoto(avatar);
    setNewPhoto('');
    const fetchData = async () => {
      const path = admin
        ? '/admin/agent-admin/profile/photo'
        : '/profile/photo';
      const body = { Agentno: agent };
      try {
        promise = API.post('DashboardAPI', path, { body });
        const response = await promise;
        if (response.data !== null) {
          setPhoto(response.data);
        }
      } catch (error) {
        if (!API.isCancel(error)) {
          captureError({ data: { error } });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      if (promise) {
        API.cancel(promise);
      }
    };
  }, [admin, agent, saved]);

  // Upload Image
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,

    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      setErrorMessage('');
      setUploadSuccess(false);
      if (fileRejections.length !== 0) {
        setErrorMessage(fileRejections[0].errors[0].message);
      } else {
        const file = acceptedFiles[0];
        setNewPhoto(URL.createObjectURL(file));
      }
    },
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
    },
  });

  // Delete Photo Function
  const handleDelete = async () => {
    setOpenDialog(!openDialog);
    setLoading(true);
    setUploadSuccess(false);
    const path = admin
      ? '/admin/agent-admin/profile/photo/delete'
      : '/profile/photo/delete';
    const body = { Agentno: agent };
    try {
      const response = await API.post('DashboardAPI', path, { body });
      if (response.status === 'ok') {
        setPhoto(avatar);
        setUploadSuccess(true);
      }
    } catch (error) {
      captureError({ data: { error } });
      setErrorMessage(responseError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} style={{ padding: 10 }}>
      <CropPhoto
        admin={admin}
        agent={agent}
        user={user}
        newPhoto={newPhoto}
        setUploadSuccess={setUploadSuccess}
        saved={setSaved}
      />

      {uploadSuccess && (
        <Alert
          sx={{ alertStyle }}
          severity="success"
          onClose={() => {
            setUploadSuccess(false);
          }}
        >
          Photo Updated!
        </Alert>
      )}
      {errorMessage && (
        <Alert
          sx={{ alertStyle }}
          severity="error"
          onClose={() => {
            setErrorMessage('');
          }}
        >
          <b>Error!</b> {errorMessage}
        </Alert>
      )}
      <Grid container justifyContent={isMobile ? 'center' : 'center'}>
        <Grid>
          <Grid
            item
            xs={12}
            style={{
              display: isMobile ? undefined : 'flex',
            }}
          >
            {loading ? (
              <div style={{ padding: isMobile ? 3 : undefined }}>
                <CircularProgress
                  size={200}
                  color="secondary"
                  thickness={0.75}
                  style={{ position: 'fixed' }}
                />

                <Skeleton variant="circular" width={200} height={200} />
              </div>
            ) : (
              <img
                alt="agent"
                src={photo}
                style={{
                  border: `4px solid ${materialTheme.palette.primary.main}`,
                  borderRadius: '50%',
                  boxShadow: '2px 2px 5px grey',
                  objectFit: 'cover',
                  height: 200,
                  width: 200,
                }}
              />
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
                flexDirection: isMobile ? undefined : 'column',
              }}
            >
              {(photo !== avatar && props.agent === props.user) ||
              (photo !== avatar && admin && props.userCanEdit) ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: red[700],
                    color: '#fff',
                    margin: isMobile ? '10px 5px' : '5px 0px 5px 15px',
                    width: '70px',
                  }}
                  onClick={() => setOpenDialog(!openDialog)}
                >
                  Delete
                </Button>
              ) : null}

              {admin && !props.userCanEdit ? null : (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />{' '}
                  <Button
                    variant="contained"
                    size="small"
                    color="info"
                    style={{
                      margin: isMobile ? '10px 5px' : '5px 0px 5px 15px',
                    }}
                    disabled={impersonatedAgent?.AgtNo !== undefined && !admin}
                    onClick={open}
                  >
                    Update
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(!openDialog);
        }}
        maxWidth="xs"
      >
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12} style={{ margin: 15, textAlign: 'center' }}>
            <Typography variant="h6">
              Are you sure you want to delete this photo?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ margin: 10, textAlign: 'center' }}>
            <Button
              disabled={loading}
              size="small"
              style={{ margin: 5 }}
              variant="contained"
              color="primary"
              onClick={handleDelete}
            >
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  style={{
                    position: 'fixed',
                  }}
                />
              )}
              Yes
            </Button>
            <Button
              disabled={loading}
              size="small"
              style={{ margin: 5 }}
              variant="contained"
              color="inherit"
              onClick={() => setOpenDialog(!openDialog)}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
}
