import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ContainerProps } from '@mui/system/Container/ContainerProps';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { appTheme, materialTheme } from '../../theme';
import { removeImpersonatedUser } from '../../utils/impersonate';
import { AnimateIn, AnimateInProps } from '../animate-in';
import { RouteError } from './route-error';
import { RouteErrorBoundary } from './route-error-boundary';
import { RouteWrapper } from './route-wrapper';

export function RouteContainer(props: {
  children: JSX.Element;
  routeTitle: string;
  withContainer?: boolean;
  maxWidth?: ContainerProps['maxWidth'];
  loading?: boolean;
  hasError?: boolean;
  paddingBottom?: number;
  nofade?: AnimateInProps['nofade'];
  notransform?: AnimateInProps['notransform'];
  noanimation?: boolean;
}) {
  // Context
  const { dispatch } = React.useContext(AppContext);
  const authContext = React.useContext(AuthContext);
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();

  React.useEffect(() => {
    // If the drawer is open when this component mounts, close it
    dispatch({ type: 'DRAWER_CLOSE' });
  }, [dispatch]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let content = props.children;
  if (props.withContainer) {
    content = <Container maxWidth={props.maxWidth}>{props.children}</Container>;
  }

  let routeContent = <div />;
  if (props.loading) {
    routeContent = <LinearProgress color="secondary" />;
  } else if (props.hasError) {
    routeContent = <RouteError />;
  } else {
    routeContent = (
      <RouteErrorBoundary>
        <RouteWrapper
          paddingBottom={props.paddingBottom}
          nofade={props.nofade}
          notransform={props.notransform}
          noanimation={props.noanimation}
        >
          {content}
        </RouteWrapper>
      </RouteErrorBoundary>
    );
  }

  const isStaff = authContext.state.authorize?.Staff !== null;
  const isImpersonating = authContext.state.impersonatedAgent !== null;

  let impersonatedUser: string | null = null;
  if (authContext.state.impersonatedAgent) {
    const { Fname, Lname } = authContext.state.impersonatedAgent;
    impersonatedUser = `${Fname} ${Lname?.charAt(0)}`;
  }

  let currentUser = authContext.state.user?.getUsername();
  if (authContext.state.authorize?.Agent) {
    const { Fname, Lname } = authContext.state.authorize.Agent;
    currentUser = `${Fname} ${Lname?.charAt(0)}`;
  } else if (authContext.state.authorize?.Staff) {
    const { Fname, Lname } = authContext.state.authorize.Staff;
    currentUser = `${Fname} ${Lname?.charAt(0)}`;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Contracting | {props.routeTitle}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${appTheme.drawerWidth}px)` },
        }}
      >
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${appTheme.drawerWidth}px)` },
            ml: { sm: `${appTheme.drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ pr: 0 }}>
            <ButtonBase
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })}
              sx={{
                mr: 2,
                display: { sm: 'none' },
                backgroundColor: '#9b9b9b88',
                padding: 1,
                borderRadius: 1,
                fontWeight: 'bold',
              }}
            >
              Menu
            </ButtonBase>

            <Box sx={{ flex: 1 }}>
              <AnimateIn nofade="true">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}
                >
                  {props.routeTitle}
                </Typography>
              </AnimateIn>
            </Box>

            <ButtonBase
              id="navigation-button"
              aria-controls={open ? 'navigation-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isImpersonating
                  ? materialTheme.palette.secondary.main
                  : isStaff
                  ? '#2196f3'
                  : '#ffffff44',
                backgroundColor: '#ffffff11',
                borderRadius: 1,
                py: { xs: 0.2, md: 0.4 },
                px: 0.6,
                display: 'flex',
                alignItems: 'center',
                flexDirection: { xs: 'column', md: 'row' },
                transition: 'all 0.2s',
              }}
            >
              {isImpersonating ? (
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: { xs: 12, md: 14 },
                    letterSpacing: 0.4,
                    whiteSpace: 'nowrap',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: isStaff ? '#2196f3' : '#ffffff44',
                    backgroundColor: '#ffffff33',
                    px: 1,
                    py: 0.4,
                    borderRadius: 1,
                    mr: 0.8,
                  }}
                >
                  {currentUser}
                </Box>
              ) : null}

              {isImpersonating ? (
                <Box>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: { xs: 14, md: 20 },
                      letterSpacing: 0.4,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {impersonatedUser}
                  </Box>

                  <Box
                    sx={{
                      textTransform: 'uppercase',
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      color: '#c6ff00',
                      fontSize: { xs: 10, md: 12 },
                    }}
                  >
                    {authContext.state.impersonatedAgent?.AgtNo}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: { xs: 14, md: 20 },
                      letterSpacing: 0.4,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {currentUser}
                  </Box>

                  <Box
                    sx={{
                      textTransform: 'uppercase',
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      color: materialTheme.palette.secondary.main,
                      fontSize: { xs: 10, md: 12 },
                    }}
                  >
                    {authContext.state.authorize?.Agent?.AgtNo ||
                      authContext.state.authorize?.Staff?.Username ||
                      authContext.state.user?.getUsername()}
                  </Box>
                </Box>
              )}
            </ButtonBase>

            <Menu
              id="navigation-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'navigation-button',
              }}
            >
              {isImpersonating ? (
                <MenuItem
                  onClick={async () => {
                    // Close menu
                    handleClose();
                    // Remove user from storage
                    await removeImpersonatedUser();
                    // Remove user from context
                    authContext.dispatch({
                      type: 'IMPERSONATE_AGENT',
                      payload: null,
                    });
                  }}
                >
                  <ListItemIcon>
                    <PersonOffIcon />
                  </ListItemIcon>
                  <ListItemText>Stop impersonating</ListItemText>
                </MenuItem>
              ) : null}
              <MenuItem
                component="a"
                href="https://arc.naaleads.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <ArrowBackIcon />
                </ListItemIcon>
                <ListItemText>Back to ARC</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  // Remove user from storage
                  await removeImpersonatedUser();
                  // Clear any cached data
                  queryClient.removeQueries();
                  // Sign out current user from Cognito
                  await Auth.signOut();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Toolbar />

        {routeContent}
      </Box>
    </React.Fragment>
  );
}
