import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { appTheme } from '../../theme';
import { CTR_GROUP } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { FastractApprovals } from './fastract-approvals';
import { GettingStarted } from './getting-started';
import { Notifications } from './notifications';
import { PendingChanges } from './pending-changes';
import { PendingContracts } from './pending-contracts';

const tabItems: {
  label: string;
  value?: string;
}[] = [
  { label: 'Fastract Approvals', value: 'FastractApprovals' },
  { label: 'Pending Changes', value: 'PendingChanges' },
  { label: 'Pending Contracts', value: 'PendingContracts' },
  { label: 'Getting Started', value: 'GettingStarted' },
  { label: 'Notifications' },
];

export function Team() {
  // Context
  const {
    state: { user, impersonatedAgent, authorize },
  } = React.useContext(AuthContext);
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasGroup, checkPermissions } = usePermissions();

  const currentTab = decodeURIComponent(
    searchParams.get('tab') || tabItems[0].value || tabItems[0].label
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // Encode the value before setting it in the URL
    setSearchParams({ tab: encodeURIComponent(newValue) });
  };

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Only Contract Authorized and Admins can take actions on the page
  const isContAuth =
    checkPermissions([
      CTR_GROUP.CTR_GlobalAdmin,
      CTR_GROUP.CTR_AgtSpecImpersonation,
    ]) ||
    Boolean(
      authorize &&
        authorize.Agent &&
        authorize.Agent.ContAuth === '1' &&
        impersonatedAgent === null
    );

  // Admins can impersonate on the Team Management page
  // Managers cannot impersonate downlines
  const enabled: boolean =
    Boolean(
      impersonatedAgent !== null &&
        (hasGroup(CTR_GROUP.CTR_GlobalAdmin) ||
          hasGroup(CTR_GROUP.CTR_AgtSpecImpersonation) ||
          hasGroup(CTR_GROUP.CTR_ImpersonateAll))
    ) || Boolean(isAgt(AgtNo) && impersonatedAgent === null);

  let impersonateWarning: JSX.Element | null = null;
  if (
    !hasGroup(CTR_GROUP.CTR_GlobalAdmin) &&
    !hasGroup(CTR_GROUP.CTR_AgtSpecImpersonation) &&
    !hasGroup(CTR_GROUP.CTR_ImpersonateAll) &&
    impersonatedAgent !== null
  ) {
    impersonateWarning = (
      <Box sx={{ width: '100%' }}>
        <Alert severity="info" sx={{ borderRadius: 0 }}>
          Impersonation does not work on this page.
        </Alert>
      </Box>
    );
  }

  return (
    <RouteContainer routeTitle="Team Management" noanimation>
      <React.Fragment>
        <Box
          sx={{
            position: 'fixed',
            width: { xs: '100%', sm: `calc(100% - ${appTheme.drawerWidth}px)` },
            borderBottom: 1,
            borderColor: 'divider',
            zIndex: 4,
            backgroundColor: '#fff',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
          >
            {tabItems.map((item) => {
              return (
                <Tab
                  key={item.label}
                  label={item.label}
                  value={item.value || item.label}
                />
              );
            })}
          </Tabs>
        </Box>

        <Tabs />

        {currentTab !== 'Notifications' && impersonateWarning}

        <Box sx={{ width: '100%' }}>
          {currentTab === 'FastractApprovals' ? (
            <FastractApprovals
              enabled={enabled}
              isContAuth={isContAuth}
              AgtNo={AgtNo}
            />
          ) : null}

          {currentTab === 'PendingChanges' ? (
            <PendingChanges
              enabled={enabled}
              isContAuth={isContAuth}
              AgtNo={AgtNo}
            />
          ) : null}

          {currentTab === 'PendingContracts' ? (
            <PendingContracts enabled={enabled} AgtNo={AgtNo} />
          ) : null}

          {currentTab === 'GettingStarted' ? (
            <GettingStarted enabled={enabled} AgtNo={AgtNo} />
          ) : null}

          {currentTab === 'Notifications' ? (
            <Notifications AgtNo={AgtNo} />
          ) : null}
        </Box>
      </React.Fragment>
    </RouteContainer>
  );
}
