import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { AgentProfileData, BackgroundData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { BackgroundInfo } from './background-info';
import { ContactInfo } from './contact-info';

export function Account() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Background Information
  const pathBackground = '/agent/background';
  const queryBackground = useQuery({
    queryKey: [pathBackground, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: BackgroundData;
        } = await API.post('ContractingAPI', pathBackground, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Agent Profile
  const pathProfile = '/agent/info';
  const queryProfile = useQuery({
    queryKey: [pathProfile, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: AgentProfileData;
        } = await API.post('ContractingAPI', pathProfile, {
          body: { AgtNo },
        });
        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <RouteContainer
      routeTitle="Agent Profile"
      withContainer
      maxWidth="lg"
      loading={queryBackground.isLoading || queryProfile.isLoading}
      hasError={queryBackground.isError || queryProfile.isLoading}
    >
      <Stack spacing={2} sx={{ pt: 2, pb: 16 }}>
        {queryProfile.data && (
          <ContactInfo
            AgtNo={AgtNo}
            data={queryProfile.data}
            user={user?.getUsername()}
          />
        )}

        {queryBackground.data ? (
          <BackgroundInfo AgtNo={AgtNo} data={queryBackground.data} />
        ) : null}
      </Stack>
    </RouteContainer>
  );
}
