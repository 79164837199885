import { TableCellProps } from '@mui/material/TableCell';

export interface ColumnHeader<T> {
  key: keyof T;
  label?: string;
  align?: TableCellProps['align'];
}

export interface TableState {
  page: number;
  rowsPerPage: number;
  sort: 'asc' | 'desc';
  sortBy: string;
}

export enum CTR_GROUP {
  CTR_ImpersonateAll = 'CTR_ImpersonateAll',
  CTR_GlobalAdmin = 'CTR_GlobalAdmin',
  ARC_Accounting = 'ARC_Accounting',
  ARC_GlobalAdmin = 'ARC_GlobalAdmin',
  CTR_AgentAdmin = 'CTR_AgentAdmin',
  CTR_AgtSpecImpersonation = 'CTR_AgtSpecImpersonation',
  ARC_ABTF = 'ARC_ABTF',
  ARC_Commissions = 'ARC_Commissions',
  ARC_ImpersonateAll = 'ARC_ImpersonateAll',
  ARC_LPT = 'ARC_LPT',
  ARC_Marketing = 'ARC_Marketing',
  ARC_PresidentsClub = 'ARC_PresidentsClub',
  AllianceEvents_Admin = 'AllianceEvents_Admin',
  AllianceEvents_CheckIn = 'AllianceEvents_CheckIn',
  ARC_BillingSystem = 'ARC_BillingSystem',
}

export interface CTR_User {
  Username: string;
  Email: string;
  Status?: string;
}

export interface CTRGroup {
  GroupName: keyof typeof CTR_GROUP;
  DisplayName: string;
  Users?: CTR_User[];
}

export interface AgentSelect {
  AgtNo: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  AgtName: string | null;
  CellPh: string | null;
  Email: string | null;
  HomeAdd: string | null;
  HomeCity: string | null;
  HomeSt: string | null;
  HomeZip: string | null;
  AgtStatus: string | null;
  IMOName?: string | null;
  LastAccessDate?: string | null;
  RegVerified?: '0' | '1' | null;
  ContAuth: '0' | '1' | null;
  HasSignedICA?: '0' | '1' | null;
  HasNIPRData?: '0' | '1' | null;
}

export interface AuthorizeIMOData {
  IMOName: string | null;
  IMODisplayName: string | null;
  IMOAgtNo: string | null;
  IMOAcronym: string | null;
  Logo: string | null;
  Address: string | null;
  Phone: string | null;
  Status: string | null;
  AgtNoFormat: null;
  BillingContact: null;
}

export interface AuthorizeStaffData {
  Username: string | null;
  Fname: string | null;
  Lname: string | null;
  IMOName: string | null;
  Active: string | null;
  LastAccessDate: string | null;
}

export interface AuthorizeData {
  IMO: AuthorizeIMOData;
  Agent: AgentSelect | null;
  Staff: AuthorizeStaffData | null;
}

export interface SearchAgentData {
  AgtNo: string;
  AgtName: string | null;
  CellPh: string | null;
  Email: string | null;
  Location: string | null;
  AgtStatus: string | null;
  ComLvl?: string | null;
}

export interface AdminAgentNoteData {
  NoteId: string;
  AgtNo: string | null;
  Note: string | null;
  Username: string | null;
  CreatedDate: string | null;
}

export interface HierarchyItem {
  AgtNo: string;
  AgtName: string;
  Level: number;
  Depth: number;
}

export interface GettingStartedHomeData {
  AgtNo: string | null;
  HasSignedICA: boolean | null;
  HasLicenses: boolean | null;
  HasCarrierContract: boolean | null;
  HasCommDepositInfo: boolean | null;
}

export interface GettingStartedTeamData extends GettingStartedHomeData {
  AgtStatus: string | null;
  AgtName: string | null;
  Email: string | null;
  Phone: string | null;
  HomeCity: string | null;
  HomeSt: string | null;
  FTDate: string | null;
  CreatedDate: string | null;
  RecAgtNo: string | null;
  RecAgtName: string | null;
}

export interface ActionItemData {
  Type: 'Contract' | 'Approve Agent';
  Title: string | null;
  Message: string | null;
  AgtDocID: number | null;
  AffectedAgtNo: string | null;
}

export interface BackgroundData {
  AgtNo: string | null;
  Bankrupt: 0 | 1 | null;
  BankruptAct: 0 | 1 | null;
  BankruptDist: string | null;
  BankruptDate: string | null;
  BankruptExpl: string | null;
  CarDebt: 0 | 1 | null;
  CarDebtDate: string | null;
  CarDebtExpl: string | null;
  CrimAny: 0 | 1 | null;
  CrimExpl: string | null;
  Felony: 0 | 1 | null;
  FelonyDate: string | null;
  FelonyExpl: string | null;
  LicRevSus: 0 | 1 | null;
  Misdemeanor: 0 | 1 | null;
  MisdemeanorExpl: string | null;
  RefuBond: 0 | 1 | null;
  RefuBondExpl: string | null;
  TermforCause: 0 | 1 | null;
  TermforCauseExpl: string | null;
  LeinOrJudgement: 0 | 1 | null;
  LeinOrJudgementExpl: string | null;
  ConsumerComplaint: 0 | 1 | null;
  ConsumerComplaintExpl: string | null;
}

export interface WalletAccountData {
  AgtACHId: number;
  Nickname: string | null;
  Routing: string | null;
  Account: string | null;
  AccountType: 'Checking' | 'Savings' | null;
  LastModified: string | null;
  ForComms: boolean | null;
  ForLeadBill: boolean | null;
  IsLeadBillPrimary: boolean | null;
}

export enum PYMNT_DFLT {
  'EVT' = 'EVT',
  'LDS' = 'LDS',
  'PRC' = 'PRC',
}

export interface CustomerPaymentData {
  customerPaymentProfileId: string | null;
  cardNumber: string | null;
  expirationDate: string | null;
  cardType: string | null;
  billToPhone: string | null;
  billToEmail: string | null;
  billToFirstName: string | null;
  billToLastName: string | null;
  billToCompany: string | null;
  billToAddress: string | null;
  billToCity: string | null;
  billToState: string | null;
  billToZip: string | null;
  billToCountry: string | null;
  defaultFor: PYMNT_DFLT[] | null;
}

export interface CustomerData {
  merchantCustomerId: string | null;
  customerProfileId: string | null;
  description: string | null;
  email: string | null;
  username: string | null;
  customerPaymentProfiles: CustomerPaymentData[];
  profileType: string | null;
}

export interface WalletData {
  billing: WalletAccountData[];
  'credit-cards': CustomerData;
}

export interface RequestChangeData {
  RequestID: number;
  AgtNo: string | null;
  Fname: string | null;
  Lname: string | null;
  Email: string | null;
  UpdateEmail: string | null;
  CellPh: string | null;
  UpdateCellPh: string | null;
  Status: string | null;
  Reason: string | null;
  FromLevel: number | null;
  ToLevel: number | null;
  FromUpline: string | null;
  FromName: string | null;
  ToUpline: string | null;
  ToName: string | null;
  RequestedBy: string | null;
  ReqByName: string | null;
  ReqByEmail: string | null;
  CreatedDate: string | null;
  ChangeType?: 'level' | 'hierarchy' | 'downline transfer' | null;
  ChangeTo?: number | string | null;
  ChangeFrom?: number | string | null;
  ReviewedBy?: string | null;
  DownlineTransfer?: boolean | null;
}

export interface FastractPendingData {
  AgtNo: string | null;
  AgtName: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  HomePh: string | null;
  CellPh: string | null;
  Email: string | null;
  OrigActivationDate: string | null;
  Recruiter: string | null;
  RecFname: string | null;
  RecLname: string | null;
}

export interface TmpltConfigData {
  TmpltID: number;
  PartRole: string | null;
  TmpltOrder: number | null;
  CondQID: number | null;
  CondAnswer: string | null;
}

export interface TmpltData {
  TmpltID: number;
  TmpltName: string | null;
  AdobeTmpltID: string | null;
  TmpltCreatedDate: string | null;
  PartRole: string | null;
  TmpltOrder: number;
  CondQID: number | null;
  CondAnswer: string | null;
}

export interface TmpltMgrData {
  'tmplt-mgr': TmpltData[];
  fields: {
    'mapped-duplicates': {
      [key: string]: string[];
    };
    mapped: {
      [key: string]: string[];
    };
    unmapped: {
      [key: string]: string[];
    };
  };
}

export interface FieldMapData {
  TmpltField: string;
  Table: string;
  Field: string;
  BackfillField: boolean;
}

export interface DocumentData {
  DocID: number;
  DispName: string;
  CarrierID: CarrierData['CarrierID'];
  EffDate: string | null;
  EndDate: string | null;
  DocStatus: string | null;
  DocType: string | null;
  Instructions: string | null;
  CreatedDate: string | null;
  ReqsStaffAdobeSign?: boolean | null;
  CreateEncrypted?: boolean | null;
  InclAudit?: boolean | null;
  AutoComplete?: boolean | null;
  FormQuestions?: DocumentQuestionData[] | null;
}

export interface DocumentCarrierGroupData {
  ListGroup: string;
  OrderIndex: number;
}

export interface DocumentCarrierData {
  DocID: DocumentData['DocID'];
  DispName: string | null;
  Carrier: string | null;
  CarrierID: CarrierData['CarrierID'];
  FullName?: CarrierData['FullName'];
  OrderIndex: number | null;
  ListGroup: DocumentCarrierGroupData['ListGroup'];
  Message: string | null;
  EffDate: string | null;
  EndDate: string | null;
  DocStatus: string | null;
  DocType: string | null;
  DocCreatedDate: string | null;
  AgtDocID: DocumentAgentData['AgtDocID'] | null;
  SignDate: null;
  Signed: boolean | null;
  AgtDocCreatedDate: string | null;
  ProcessDate: null;
  SubmitDate: null;
  SignerEmail: string | null;
  Instructions: string | null;
  HasFormQuestions: boolean | null;
}

export interface DocumentContractData {
  CarListID: number;
  CarrierID: CarrierData['CarrierID'];
  Carrier: string | null;
  FullName: string | null;
  Logo: string | null;
  DocID: DocumentCarrierData['DocID'];
  DocName: string | null;
  DocType: string | null;
  Instructions: string | null;
  OrderIndex: number;
  ListGroup: DocumentCarrierGroupData['ListGroup'];
  Message: string | null;
  FormQuestions: DocumentQuestionData[] | null;
}

export enum DocumentQuestionType {
  'Checkbox' = 'Checkbox',
  'Date' = 'Date',
  'Datetime' = 'Datetime',
  'Number' = 'Number',
  'Radio' = 'Radio',
  'Select' = 'Select',
  'Text' = 'Text',
}

export interface DocumentQuestionData {
  QID: number;
  Req: boolean | null;
  Question: string;
  QType: DocumentQuestionType | null;
  PrefillTmpltField: string | null;
  ChoiceAnswers: string[] | null;
  PrefillAnswer: string | null;
  Answer: string[] | string | null;
  PrefillOnly: boolean | null;
}

export interface DocAdminQuestionData {
  QID: DocumentQuestionData['QID'];
  Question: DocumentQuestionData['Question'];
  QType: DocumentQuestionData['QType'];
  PrefillTmpltField: DocumentQuestionData['PrefillTmpltField'];
  ChoiceAnswers: DocumentQuestionData['ChoiceAnswers'];
}

export interface DocumentAgentData {
  AgtDocID: number;
  AgtNo: string | null;
  SignDate: null;
  Signed: boolean | null;
  CreatedDate: string | null;
  ProcessDate: null;
  SubmitDate: null;
  DocID: DocumentCarrierData['DocID'];
  ProcStatus: null;
  AdobeDocID: string | null;
  Filename: string | null;
  FileStreamID: null;
  SignerEmail: string | null;
  ApproverEmail: string | null;
  SignerUrl: string | null;
  PendingReasons: DocumentPendingData[];
  CarrierID: CarrierData['CarrierID'];
  Instructions: string | null;
  HasFormQuestions: boolean | null;
}

export interface PendingReasonData {
  PendID: number;
  Reason: string;
  CarrierID: CarrierData['CarrierID'] | null;
}

export interface DocumentPendingData {
  AgtDocID: DocumentAgentData['AgtDocID'];
  Username: string;
  AssignDate: string;
  PendID: PendingReasonData['PendID'];
  Reason: PendingReasonData['Reason'];
  // null value for CarrierID means that this reason applies to all carriers
  CarrierID: CarrierData['CarrierID'] | null;
}

export enum ProcStatus {
  'Business Pending (Complete)' = 'Business Pending (Complete)',
  'Closed' = 'Closed',
  'Complete' = 'Complete',
  'Incomplete' = 'Incomplete',
  'Invite Sent' = 'Invite Sent',
  'New' = 'New',
  'Pending' = 'Pending',
  'Pending (legacy)' = 'Pending (legacy)',
  'Sent Manually (Complete)' = 'Sent Manually (Complete)',
}

export interface ApprovalData {
  AgtName: string | null;
  DispName: string | null;
  DocType: string | null;
  Carrier: string | null;
  ApproverURL: string | null;
  Notes: string | null;
  FormQuestions: DocumentQuestionData[];
  AgtDocID: DocumentAgentData['AgtDocID'];
  AgtNo: string | null;
  SignDate: string | null;
  Signed: boolean | null;
  CreatedDate: string | null;
  ProcessDate: string | null;
  SubmitDate: string | null;
  DocID: DocumentCarrierData['DocID'];
  ProcStatus: ProcStatus;
  AdobeDocID: string | null;
  Filename: string | null;
  FileStreamID: string | null;
  SignerEmail: string | null;
  ApproverEmail: string | null;
  SignerUrl: string | null;
  PendingReasons: DocumentPendingData[] | null;
}

export interface PendingContractData {
  CarrierID: CarrierData['CarrierID'];
  Carrier: string | null;
  FullName: string | null;
  Logo: string | null;
  DocID: DocumentData['DocID'];
  AgtDocID: DocumentAgentData['AgtDocID'];
  CreatedDate: string | null;
  Signed: boolean;
  ProcStatus: ProcStatus;
  AgtNo: string;
  AgtName: string | null;
  Email: string | null;
  PendingReasons: DocumentPendingData[];
}

export enum FileUploadType {
  'Errors and Omissions' = 'Errors and Omissions',
  'Bankruptcy Documents' = 'Bankruptcy Documents',
  'Carrier Debt Documents' = 'Carrier Debt Documents',
  'Dept of Insurance Complaints' = 'Dept of Insurance Complaints',
  'Felony Documents' = 'Felony Documents',
  'Anti-Money Laundering' = 'Anti-Money Laundering',
  'AHIP' = 'AHIP',
  'Annuity' = 'Annuity',
  'Insurance License' = 'Insurance License',
}

export interface FileUploadData {
  FileUploadID: number;
  AgtNo: string;
  StreamID: string | null;
  FileUploadType: FileUploadType;
  Filename: string | null;
  FileDisplayName: string | null;
  MimeType: string | null;
  CreatedDate: string | null;
}

export interface CarrierData {
  CarrierID: number;
  Carrier: string;
  FullName: string;
  CommStatus: string | null;
  ProcComs: 0 | 1 | null;
  ContrStatus: string | null;
  IMOUpline: string | null;
  FEIN: string | null;
  Logo: string | null;
  AgtSpec: 0 | 1 | null;
  Notes: string | null;
  IMOWrtNo: string | null;
  MaxWrtCarNo: number;
}

export interface CarrierAdminData {
  CarrierID: string | number;
  Carrier: string;
  FullName: string;
  CommStatus: string | null;
  ProcComs: boolean | null;
  ContrStatus: string | null;
  IMOUpline: string | null;
  FEIN: string | null;
  Logo: string | null;
  AgtSpec: boolean | null;
  ReqLic: boolean | null;
  Notes: string | null;
  IMOWrtNo: string | null;
  MaxWrtCarNo: number | null;
  CreatedDate: string | null;
  CarrierEmail: string | null;
  MatrixRuleID: number | null;
  CarrierMagic: number | null;
}

export interface MyContractData {
  CarrierID: CarrierData['CarrierID'];
  Name: string | null;
  Logo: string | null;
  DocID: DocumentData['DocID'];
  AgtDocID: DocumentAgentData['AgtDocID'];
  CreatedDate: string | null;
  Signed: boolean | null;
  ProcStatus: ProcStatus;
  PendingReasons: DocumentPendingData[] | null;
}

export interface MyContractsData {
  'waiting-on-you': MyContractData[];
  'waiting-on-you-to-write-business': MyContractData[];
  'waiting-on-us': MyContractData[];
  'waiting-on-carrier': MyContractData[];
  active: MyContractData[];
  closed: MyContractData[];
  unknown: MyContractData[];
}

export interface TemplateData {
  id: string | null;
  name: string | null;
  creatorEmail: string | null;
  ownerEmail: string | null;
  modifiedDate: string | null;
  sharingMode: string | null;
  status: string | null;
}

export interface AdobeTemplateData {
  TmpltID: number;
  TmpltName: string;
  AdobeTmpltID: string;
  CreatedDate: string;
}

export interface AgentNPNData {
  AgtNo: string;
  AgtName: string;
  NPN: number;
  LockNPN: 0 | 1;
}

export interface AgentWrtNoData {
  AgtCarInfoId: number;
  AgtNo: string;
  CarrierID: CarrierData['CarrierID'];
  WrtCarNo: string;
}

export interface EOPolicyData {
  AgtNo: string | null;
  EOCovAmt: number | null;
  EOEffDate: string | null;
  EOExpDate: string | null;
  EOCarrier: string | null;
  EOPolNo: string | null;
  EOCertNo: string | null;
  StreamID: FileUploadData['StreamID'];
  FileUploadID: FileUploadData['FileUploadID'];
  Filename: FileUploadData['Filename'];
  MimeType: FileUploadData['MimeType'];
  EODecline: string | null;
  EODecExpl: string | null;
}

export interface LicenseData {
  ClientKey?: string;
  LicId: number;
  State: string | null;
  LicNo: string | null;
  DateUpdated: string | null;
  DateIss: string | null;
  DateExp: string | null;
  ClassId: number;
  Class: string | null;
  ClassCode: number | null;
  ResStatus: string | null;
  Active: string | null;
  IsNIPR: boolean | null;
  Details: LicenseDetailData[];
}

export interface LicenseDetailData {
  LOAId: number;
  LOA: string | null;
  LOACode: number | null;
  DateIss: string | null;
  LOAStatus: string | null;
  Reason: string | null;
  ReasonDate: string | null;
}

export interface AppointmentData {
  ApptId: number;
  State: string;
  Company: string;
  FEIN: string;
  CoCode: number | null;
  LOA: string | null;
  LOACode: number | null;
  ApptStatus: string | null;
  TermReason: string | null;
  ReasonDate: string | null;
  RenewalDate: string | null;
}

export enum AgentStatusChoice {
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'New' = 'New',
  'Returning' = 'Returning',
  'Terminated' = 'Terminated',
  'Terminated-No Rehire' = 'Terminated-No Rehire',
  'Terminated-DupeAgent' = 'Terminated-DupeAgent',
}

export interface AgentProfileData {
  AgtNo: string | null;
  AgtStatus: string | null;
  Level: number | null;
  AgtType: string | null;
  AgtName: string | null;
  AliasName: string | null;
  Prefix: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  Suffix: string | null;
  Title: string | null;
  Gender: string | null;
  Last4SSN: string | null;
  DOB: string | null;
  Email: string | null;
  Email2: string | null;
  HomePh: string | null;
  CellPh: string | null;
  Fax: string | null;
  HomeAdd: string | null;
  HomeAptSte: string | null;
  HomeCity: string | null;
  HomeSt: string | null;
  HomeZip: string | null;
  HomeCnty: string | null;
  MailToAdd: string | null;
  MailToAdptSte: string | null;
  MailToCity: string | null;
  MailToSt: string | null;
  MailToZip: string | null;
  Recruiter: string | null;
  RecName: string | null;
  DrivLic: string | null;
  DrivLicSt: string | null;
  MaidName: string | null;
  MaritalStatus: string | null;
  SpouseName: string | null;
  HasCorp: 0 | 1 | null;
  CorpName: string | null;
  TaxID: string | null;
  BusAdd: string | null;
  BusAptSte: string | null;
  BusCity: string | null;
  BusSt: string | null;
  BusZip: string | null;
  BusPh: string | null;
  Website: string | null;
  NPN: string | null;
  PrevAdd: string | null;
  PrevAptSte: string | null;
  PrevCity: string | null;
  PrevSt: string | null;
  PrevZip: string | null;
  PresAddLth: string | null;
  PrevAddLth: string | null;
  Licensed: 0 | 1 | null;
  USCitizen: 0 | 1 | null;
  ContAuth: 0 | 1 | null;
}

export interface CRMAgent {
  AgentNumber: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  AgentStatus: string;
  AgentLevel: string;
  RecAgentNumber: string;
  RecFirstName: string;
  RecLastName: string;
  RecMiddleName: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  Email: string;
  AlternateEmail: string;
  HomePhone: string;
  CellPhone: string;
  BusinessPhone: string;
  Fax: string;
  DateEntered: string;
  RTSDate: string | null;
  YTDAmount: string | null;
  Latitude: number | null;
  Longitude: number | null;
}

export interface AnnouncementData {
  AnnouncementId: number;
  StartDate: string | null;
  EndDate: string | null;
  Title: string | null;
  Description: string | null;
  Image: string | null;
}

export interface NotificationData {
  NotificationId: number | null;
  Message: string | null;
  CreatedDate: string | null;
  Read: boolean | null;
}

export interface IMOData {
  IMOName: string | null;
  IMODisplayName: string | null;
  IMOAgentNo: string | null;
  IMOAcronym: string | null;
  Logo: string | null;
  Address: string | null;
  Phone: string | null;
  Status: string | null;
  AgtNoFormat: string | null;
  BillingContact: string | null;
  CognitoGroup: string | null;
  MaxAutoAprvLvl: number | null;
}
