import AddTaskIcon from '@mui/icons-material/AddTask';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { Agreement } from '../../components/agreement';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import {
  AnnouncementData,
  GettingStartedHomeData,
  NotificationData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { ActionItems } from './action-items';
import { Announcements } from './announcements';
import { GettingStarted } from './getting-started';
import { Greeting } from './greeting';
import { Notifications } from './notifications';

export function Home() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [viewAgree, setViewAgree] = React.useState(false);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  // Query - Action Items
  const pathAction = '/agent/action-items';
  const queryAction = useQuery({
    enabled: isAgt(AgtNo),
    queryKey: [pathAction, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response = await API.post('ContractingAPI', pathAction, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Getting started
  const pathStarted = '/agent/getting-started';
  const queryStarted = useQuery({
    enabled: isAgt(AgtNo),
    queryKey: [pathStarted, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: GettingStartedHomeData;
        } = await API.post('ContractingAPI', pathStarted, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Notifications
  const pathNotifications = '/notifications/current';
  const queryNotifications = useQuery({
    enabled: isAgt(AgtNo),
    queryKey: [pathNotifications, { AgtNo }],
    queryFn: async () => {
      const response: {
        data: NotificationData[];
      } = await API.post('ContractingAPI', pathNotifications, {
        body: { AgtNo },
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Announcements
  const pathAnnouncements = '/announcements';
  const queryAnnouncements = useQuery({
    queryKey: [pathAnnouncements],
    queryFn: async () => {
      const response: {
        data: AnnouncementData[];
      } = await API.post('ContractingAPI', pathAnnouncements, {});

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const displayGettingStarted = isAgt(AgtNo);

  const isLoading =
    Boolean(queryStarted.isLoading && queryStarted.fetchStatus !== 'idle') ||
    Boolean(queryAction.isLoading && queryAction.fetchStatus !== 'idle');

  return (
    <RouteContainer
      routeTitle="Home"
      withContainer
      maxWidth={displayGettingStarted ? 'lg' : 'md'}
      loading={isLoading}
      hasError={queryStarted.isError || queryAction.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          <Greeting />

          <Agreement
            open={viewAgree}
            hideIfCompleted
            onClose={() => setViewAgree(false)}
          />

          <Box>
            <Grid
              container
              spacing={4}
              sx={{
                flexDirection: {
                  xs: 'column-reverse',
                  md: 'row',
                },
              }}
            >
              <Grid
                item
                xs={12}
                md={displayGettingStarted ? 6 : 12}
                lg={displayGettingStarted ? 7 : 12}
              >
                <Stack spacing={2}>
                  <Box sx={{ px: 1, display: 'flex' }}>
                    <Box sx={{ transform: 'rotate(-12deg)', pr: 2 }}>
                      <AddTaskIcon fontSize="large" />
                    </Box>

                    <Typography variant="h4" component="div">
                      Your Dashboard
                    </Typography>
                  </Box>

                  <ActionItems data={queryAction.data || []} />

                  <Notifications data={queryNotifications.data || []} />

                  <Announcements data={queryAnnouncements.data || []} />
                </Stack>
              </Grid>

              {displayGettingStarted && queryStarted.data ? (
                <Grid item xs={12} md={6} lg={5}>
                  <GettingStarted
                    data={queryStarted.data}
                    onViewAgree={() => setViewAgree(true)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </RouteContainer>
  );
}
