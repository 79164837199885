import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { GettingStartedHomeData } from '../../types';
import { tasks } from './data';

export function GettingStarted(props: {
  data: GettingStartedHomeData;
  onViewAgree: () => void;
}) {
  return (
    <Stack spacing={2}>
      <Box sx={{ px: 1, display: 'flex' }}>
        <Box sx={{ transform: 'rotate(-12deg)', pr: 1 }}>
          <SportsScoreIcon fontSize="large" />
        </Box>

        <Typography variant="h4" component="div">
          Getting Started
        </Typography>
      </Box>

      <Box>
        {tasks.map((task, index) => {
          let isCompleted = false;
          if (task.key === 'Fastract') {
            isCompleted = true;
          } else if (task.key in props.data) {
            // @ts-ignore
            isCompleted = Boolean(props.data[task.key]);
          }

          if (task.key === 'HasSignedICA') {
            return (
              <Box key={task.Title}>
                {index === 0 ? null : (
                  <Box
                    sx={{
                      marginLeft: 2.6,
                      height: 12,
                      width: 2,
                      backgroundColor: '#00000022',
                    }}
                  />
                )}

                <Paper
                  elevation={0}
                  component="button"
                  onClick={props.onViewAgree}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1,
                    textDecorationLine: 'none',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: isCompleted ? '#00C86888' : '#03182E22',
                    backgroundColor: isCompleted ? '#00C86804' : '#fff',
                    cursor: !isCompleted ? 'pointer' : 'inherit',
                    transition: 'all 0.2s',
                    ':hover': {
                      transform: !isCompleted ? 'scale(1.01)' : 'none',
                      borderColor: isCompleted
                        ? '#00C86888'
                        : !isCompleted
                        ? '#03182E'
                        : '#03182E22',
                    },
                    ':hover #arrow-icon': {
                      color: '#03182E',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isCompleted ? (
                      <CheckCircleIcon color="secondary" />
                    ) : (
                      <RadioButtonUncheckedIcon sx={{ color: '#9b9b9b' }} />
                    )}
                  </Box>

                  <Box sx={{ flex: 1, pl: 1, pr: 1, textAlign: 'left' }}>
                    <Box
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: '#03182E',
                        py: 0.3,
                      }}
                    >
                      {task.Title}
                    </Box>
                    <Box sx={{ fontSize: 12, color: '#4a4a4a', py: 0.3 }}>
                      {task.Description}
                    </Box>
                  </Box>

                  {!isCompleted ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ArrowRightIcon
                        id="arrow-icon"
                        fontSize="small"
                        sx={{ transition: 'all 0.2s', color: '#03182E22' }}
                      />
                    </Box>
                  ) : null}
                </Paper>
              </Box>
            );
          } else {
            return (
              <Box key={task.Title}>
                {index === 0 ? null : (
                  <Box
                    sx={{
                      marginLeft: 2.6,
                      height: 12,
                      width: 2,
                      backgroundColor: '#00000022',
                    }}
                  />
                )}

                <Paper
                  elevation={0}
                  component={task.Path ? Link : 'div'}
                  to={task.Path}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1,
                    textDecorationLine: 'none',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: isCompleted ? '#00C86888' : '#03182E22',
                    backgroundColor: isCompleted ? '#00C86804' : '#fff',
                    cursor: task.Path ? 'pointer' : 'inherit',
                    transition: 'all 0.2s',
                    ':hover': {
                      transform: task.Path ? 'scale(1.01)' : 'none',
                      borderColor: isCompleted
                        ? '#00C86888'
                        : task.Path
                        ? '#03182E'
                        : '#03182E22',
                    },
                    ':hover #arrow-icon': {
                      color: '#03182E',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isCompleted ? (
                      <CheckCircleIcon color="secondary" />
                    ) : (
                      <RadioButtonUncheckedIcon sx={{ color: '#9b9b9b' }} />
                    )}
                  </Box>

                  <Box sx={{ flex: 1, pl: 1, pr: 1 }}>
                    <Box sx={{ fontWeight: 'bold', color: '#03182E' }}>
                      {task.Title}
                    </Box>
                    <Box sx={{ fontSize: 12, color: '#4a4a4a' }}>
                      {task.Description}
                    </Box>
                  </Box>

                  {task.Path ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ArrowRightIcon
                        id="arrow-icon"
                        fontSize="small"
                        sx={{ transition: 'all 0.2s', color: '#03182E22' }}
                      />
                    </Box>
                  ) : null}
                </Paper>
              </Box>
            );
          }
        })}
      </Box>
    </Stack>
  );
}
